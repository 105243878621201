import React from 'react'
import { useDealership } from 'DealershipContext'
import { useUser } from 'UserContext'
import { errorKind } from 'services/api'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import Spinner from 'components/Spinner'
import useApi from 'components/UseApi'
import Link from 'components/Link'
import Overlay from 'components/Overlay'
import Form from 'components/Form'
import Message from 'components/Message'
import PaymentMethod from './components/PaymentMethod'
import styles from './PaymentMethods.module.css'

const PaymentMethodsPage = () => {
    const { user, setUser } = useUser()
    const { dealership } = useDealership()

    // Retrieve payment methods.
    const [paymentMethods] = useApi({
        method: 'get',
        endpoint: `/payment-methods?user_id=${user.id}&dealership_id=${dealership.id}`
    })

    // Prepare a request to delete a payment method.
    const [deleteResponse, doDelete] = useApi()

    const reducer = (state, action) => {
        switch (action.type) {
            case 'SET_PAYMENT_METHODS':
                return {
                    ...state,
                    paymentMethods: action.payload
                }
            case 'BEGIN_DELETE':
                return {
                    ...state,
                    deleteTarget: action.payload,
                    showDeleteError: false
                }
            case 'CANCEL_DELETE':
                return {
                    ...state,
                    deleteTarget: null
                }
            case 'DELETE_COMPLETE':
                return {
                    ...state,
                    paymentMethods: action.payload.error
                        ? state.paymentMethods
                        : state.paymentMethods.filter(
                              (pm) => pm.id !== state.deleteTarget.id
                          ),
                    deleteTarget: null,
                    showDeleteError: !!action.payload.error // Show the error if there is one.
                }
            default:
                throw new Error()
        }
    }

    const [state, dispatch] = React.useReducer(reducer, {
        paymentMethods: [],
        deleteTarget: null,
        showDeleteError: false
    })

    // Handle data retrieval.
    React.useEffect(() => {
        if (paymentMethods.data) {
            dispatch({
                type: 'SET_PAYMENT_METHODS',
                payload: paymentMethods.data
            })
        }
    }, [paymentMethods.data])

    // Handle deletion.
    const deleteTargetRef = React.useRef(state.deleteTarget);

    React.useEffect(() => {
        deleteTargetRef.current = state.deleteTarget;
    }, [state.deleteTarget]);

    React.useEffect(() => {
        if (!deleteResponse || deleteResponse.loading) {
            return
        }

        if (deleteResponse.error) {
            if (deleteTargetRef.current) {
                dispatch({
                    type: 'BEGIN_DELETE',
                    payload: {
                        ...deleteTargetRef.current,
                        force: true,
                        message: `The payment method ${deleteTargetRef.current.name} is linked to an active recurring payment. Deleting it will also disable the recurring payment. Are you sure you want to proceed with the deletion?`
                    }
                })
            }
        } else { 
            dispatch({ type: 'DELETE_COMPLETE', payload: deleteResponse });
        }
    }, [deleteResponse, dispatch])


    if (paymentMethods.error) {
        // If the error is the unauthorized error, change the user to no user.
        // (This should redirect us and cause this component to be unmounted.)
        if (paymentMethods.error.kind === errorKind.REAUTHENTICATION_REQUIRED) {
            setUser(null)
            return (
                <PageWrapper>
                    <Spinner />
                </PageWrapper>
            )
        }
        throw paymentMethods.error
    }

    let paymentMethodList = <Spinner />
    if (!paymentMethods.loading) {
        if (state.paymentMethods.length === 0) {
            paymentMethodList = (
                <p>
                    <small>(No payment methods exist.)</small>
                </p>
            )
        } else {
            paymentMethodList = (
                <ul className={styles.list}>
                    {state.paymentMethods.map((pm) => {
                        return (
                            <li key={pm.id}>
                                <PaymentMethod
                                    pm={pm}
                                    onDelete={(name) => {
                                        dispatch({
                                            type: 'BEGIN_DELETE',
                                            payload: {
                                                id: pm.id,
                                                name,
                                                force: false
                                            }
                                        })
                                    }}
                                />
                            </li>
                        )
                    })}
                </ul>
            )
        }
    }

    return (
        <PageWrapper>
            <PageTitle>Payment Methods</PageTitle>
            <p>
                <Link to="/new-payment-method">Add Payment Method</Link>
            </p>
            {deleteResponse.error && state.showDeleteError && (
                <Message isError={true}>
                    <p>
                        {deleteResponse.error.message}
                    </p>
                </Message>
            )}
            {paymentMethodList}
            {state.deleteTarget && (
                <Overlay>
                    <Form
                        disabled={deleteResponse.loading}
                        onSubmit={() =>
                            doDelete({
                                method: 'delete',
                                endpoint: `/payment-methods/${state.deleteTarget.id}?force_delete=${state.deleteTarget.force}`
                            })
                        }
                        onCancel={() => dispatch({ type: 'CANCEL_DELETE' })}
                        submitButtonText={'Yes'}
                        cancelButtonText={'No'}
                    >
                        <p>
                            { state.deleteTarget.message ? state.deleteTarget.message : `Are you sure to want to delete ${state.deleteTarget.name}?` }
                        </p>
                    </Form>
                </Overlay>
            )}
        </PageWrapper>
    )
}

export default PaymentMethodsPage
