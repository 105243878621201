import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDealership } from 'DealershipContext'
import { useUser } from 'UserContext'
import { errorKind } from 'services/api'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import Card from 'components/Card'
import Panel from 'components/Panel'
import Spinner from 'components/Spinner'
import Link from 'components/Link'
import useApi from 'components/UseApi'
import VehicleDue from 'components/VehicleDue'
import VehicleUpdated from 'components/VehicleUpdated'
import SubscriptionForm from './components/SubscriptionForm'
import ContactDealership from 'components/ContactDealership'
import AccountDetails from './components/AccountDetails'
import styles from './Subscription.module.css'

const SubscriptionPage = ({ match }) => {
    // Extract contract ID from the route parameters.
    const contractID = match.params.contractid

    // Use some context.
    const { dealership } = useDealership()
    const { user, setUser } = useUser()
    // State to handle redirecting to another page.
    const [redirect, setRedirect] = useState()

    // API calls for necessary information.
    const [contract, refetchContract] = useApi({
        method: 'get',
        endpoint: `/contracts/${contractID}`
    })
    const [paymentMethods, refetchPaymentMethods] = useApi({
        method: 'get',
        endpoint: `/payment-methods?user_id=${user.id}&dealership_id=${dealership.id}`
    })
    const [payments] = useApi({
        method: 'get',
        endpoint: `/payments?contract_id=${contractID}&order=created_desc`
    })
    const [subscription, refetchSubscription] = useApi({
        method: 'get',
        endpoint: `/subscription/${contractID}`
    })

    // Handle redirect being set to true.
    if (redirect) {
        return <Redirect push to={redirect} />
    }
    // Handle errors for any of the API calls, except for the subscription call, as it returns a 4XX HTTP status code when no subscription is found.
    if (
        contract.error ||
        paymentMethods.error ||
        payments.error ||
        (subscription.error && subscription.error?.kind !== errorKind.NOT_FOUND)
    ) {
        let error
        if (contract.error) {
            error = contract.error
        } else if (paymentMethods.error) {
            error = paymentMethods.error
        } else if (subscription.error) {
            error = subscription.error
        } else {
            error = payments.error
        }

        // Handle kinds of errors.
        switch (error.kind) {
            case errorKind.REAUTHENTICATION_REQUIRED:
                // The error is the unauthorized error, change the user to no user.
                // (This should redirect us and cause this component to be unmounted.)
                setUser(null)
                return (
                    <PageWrapper>
                        <Spinner />
                    </PageWrapper>
                )
            case errorKind.NOT_FOUND:
            case errorKind.PERMISSION_DENIED:
                // These errors suggest that the user is trying to access a record they are not authorized or associated with.
                // Throw the user back to their home page.
                return <Redirect to={'/'} />
            default:
                throw error
        }
    }

    // Handle loading state for any of the API calls.
    if (
        contract.loading ||
        paymentMethods.loading ||
        payments.loading ||
        subscription.loading
    ) {
        return (
            <PageWrapper>
                <Spinner />
            </PageWrapper>
        )
    }

    // Prep contents for the <Panel>
    let panelContents
    if (contract.data.payments_disabled) {
        panelContents = (
            <>
                <p>
                    {dealership.business_name} has disabled online payments for
                    this vehicle.
                </p>
                <ContactDealership />
            </>
        )
    } else if (paymentMethods.data && paymentMethods.data.length === 0) {
        panelContents = (
            <p>
                <Link
                    to="/new-payment-method"
                    className={styles.addPaymentMethodLink}
                >
                    Add Payment Method
                </Link>
            </p>
        )
    } else {
        panelContents = (
            <SubscriptionForm
                contract={contract.data}
                paymentMethods={paymentMethods.data}
                subscription={subscription.data}
                user={user}
                onCancel={(refresh) => {
                    if (refresh) {
                        // Refetch all information related to a subscription.
                        refetchContract()
                        refetchPaymentMethods()
                        refetchSubscription()
                    } else {
                        // If the payment form is displayed, the user likely
                        // came from the root (vehicles) page.
                        setRedirect('/vehicle/' + contract.data.id)
                    }
                }}
                onSuccess={(action) => {
                    if (action === 'cancel') {
                        setRedirect(
                            `/vehicle/${contract.data.id}?cancelled=true`
                        )
                    } else {
                        setRedirect('/vehicle/' + contract.data.id)
                    }
                }}
            />
        )
    }

    const updated = <VehicleUpdated contract={contract.data} />
    return (
        <PageWrapper>
            <Card topDetail={updated} className={styles.card}>
                <div className={styles.cardContent}>
                    <PageTitle className={styles.title}>
                        {contract.data.model_year} {contract.data.make}{' '}
                        {contract.data.model}
                    </PageTitle>
                    <VehicleDue
                        contract={contract.data}
                        className={styles.due}
                    />
                    <Panel className={styles.panel}>{panelContents}</Panel>
                    {!contract.data.awaiting_update && (
                        <AccountDetails
                            contract={contract.data}
                            lastPayment={
                                payments.data && payments.data.length > 0
                                    ? payments.data[0]
                                    : null
                            }
                        />
                    )}
                </div>
            </Card>
            {contract.data.payments_disabled ? (
                <Link to="/past-vehicles" button={true}>
                    Back to Past Vehicles
                </Link>
            ) : (
                <Link to="/" button={true}>
                    Back to Vehicles
                </Link>
            )}
        </PageWrapper>
    )
}

SubscriptionPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            contractid: PropTypes.string
        })
    }).isRequired
}

export default SubscriptionPage
