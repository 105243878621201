import React from 'react'
import PropTypes from 'prop-types'
import {
    contractPropType,
    paymentMethodPropType,
    subscriptionPropType,
    userPropType
} from 'proptypes'
import { useDealership } from 'DealershipContext'
import { formatMoney } from 'services/fmt'
import { errorKind } from 'services/api'
import LabeledInput from 'components/LabeledInput'
import DatePicker from 'components/DatePicker'
import Form from 'components/Form'
import Overlay from 'components/Overlay'
import ReauthenticateForm from 'components/ReauthenticateForm'
import useApi from 'components/UseApi'
import Heading from 'components/Heading'
import ConfirmPayment from './components/ConfirmSubscription'
import ConfirmCancelSubscription from './components/ConfirmCancelSubscription'
import Spinner from 'components/Spinner'
import styles from './SubscriptionForm.module.css'

// Returns the amount to which a payment was restricted (min or max depending on
// the restriction in question). Guarantess the amount is >= 0.
const paymentRestrictionAmount = (restriction, contract) => {
    let amount
    switch (restriction.kind) {
        case 'flat':
            amount = restriction.flat_amount
            break
        case 'regular':
            amount = contract.regular_payment_amount
            break
        case 'due':
            amount = contract.due_amount
            break
        default:
            amount = 0
    }

    if (!amount || amount < 0) {
        return 0
    }
    return amount
}

/**
 * Form used to make create subscription.
 */
const SubscriptionForm = ({
    contract,
    paymentMethods,
    subscription,
    user,
    onCancel,
    onSuccess
}) => {
    const { dealership } = useDealership()
    const [reauthenticate, setReauthenticate] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [shouldRefresh, setShouldRefresh] = React.useState(false)
    const [confirm, setConfirm] = React.useState(false)
    const [confirmCancel, setConfirmCancel] = React.useState(false)

    const [amount, setAmount] = React.useState(
        subscription?.amount.toString() ||
            contract.regular_payment_amount.toString() ||
            0
    )
    const [startDate, setStartDate] = React.useState(() => {
        if (subscription?.start_date) {
            return new Date(subscription?.start_date)
        } else {
            const today = new Date(new Date())
            today.setDate(today.getDate() + 1) // Add 1 day
            return today
        }
    })
    const [paymentInterval, setPaymentInterval] = React.useState(
        subscription?.interval || 'monthly'
    )
    const [stopAutoPayOn, setStopAutoPayOn] = React.useState(
        subscription?.autopay_stop_on || 'zero_balance'
    )
    const [numberOfPayments, setNumberOfPayments] = React.useState(
        subscription?.number_of_payments || 2
    )
    const [endDate, setEndDate] = React.useState(() => {
        if (subscription?.end_date) {
            return new Date(subscription?.end_date)
        }
        const today = new Date()
        today.setDate(today.getDate() + 2) // Add 2 day
        return today
    })

    const getSelectedPaymentMethod = (payment_method_id) => {
        if (paymentMethods.length > 0) {
            return payment_method_id
                ? paymentMethods.find((d) => d.id === payment_method_id) ||
                      paymentMethods[0]
                : paymentMethods[0]
        }
        return {}
    }

    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(
        getSelectedPaymentMethod(subscription?.payment_method_id || null)
    )
    const [firstPayment, setFirstPayment] = React.useState(
        subscription?.first_payment || 1
    )
    const [secondPayment, setSecondPayment] = React.useState(
        subscription?.second_payment || 15
    )
    const [isValidSemiMonthly, setIsValidSemiMonthly] = React.useState(true)

    const [tomorrowsDate] = React.useState(() => {
        const today = new Date()
        today.setDate(today.getDate() + 1) // Add 1 day
        return today
    })

    // Handler for when the startDate is changed
    const setStartDateHandler = (newDateValue) => {
        // newDateValue.setHours(0, 0, 0, 0)
        // Ensure the date is not in the past
        if (
            newDateValue.setHours(0, 0, 0, 0) <
            tomorrowsDate.setHours(0, 0, 0, 0)
        ) {
            // In the past, ignore change
            return
        }
        // Update the value
        setStartDate(newDateValue)
    }

    const dayAftertomorrowsDate = React.useMemo(() => {
        const date = new Date(startDate)
        date.setDate(date.getDate() + 1) // Add 1 day
        return date
    }, [startDate])

    // Handler for when the endDate is changed
    const setEndDateHandler = (newDateValue) => {
        // Ensure the date is not in the past
        if (
            newDateValue.setHours(0, 0, 0, 0) < startDate.setHours(0, 0, 0, 0)
        ) {
            // In the past, ignore change
            return
        }
        // Update the value
        setEndDate(newDateValue)
    }

    const [subscriptionAction, doSubscriptionAction] = useApi()
    const [cancelSubscriptionAction, doCancelSubscriptionAction] = useApi()

    const totalAmount =
        (Number.parseFloat(amount) || 0) 

    const formatDate = (date) => {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}T00:00:00Z`
    }

    const doSubscriptionCfg = {
        method: 'post',
        endpoint: '/subscription',
        body: {
            contract_id: contract.id,
            payment_method_id: selectedPaymentMethod.id,
            amount: Number.parseFloat(amount),
            start_date: formatDate(startDate),
            interval: paymentInterval,
            autopay_stop_on: stopAutoPayOn
        }
    }

    const doCancelSubscriptionCfg = {
        method: 'post',
        endpoint: '/cancel-subscription',
        body: {
            contract_id: contract.id,
            subscription_id: subscription?.id
        }
    }

    if (paymentInterval === 'semi_monthly') {
        doSubscriptionCfg.body.first_payment = firstPayment
        doSubscriptionCfg.body.second_payment = secondPayment
    }

    if (stopAutoPayOn === 'stop_date') {
        doSubscriptionCfg.body.end_date = formatDate(endDate)
    }

    if (stopAutoPayOn === 'number_payments') {
        doSubscriptionCfg.body.number_of_payments = numberOfPayments
    }

    // Hook to handle API response
    React.useEffect(() => {
        if (subscriptionAction.loading || cancelSubscriptionAction.loading) {
            return
        }

        const handleActionError = (actionError) => {
            if (!actionError) return false

            const { kind, message } = actionError
            switch (kind) {
                case errorKind.REAUTHENTICATION_REQUIRED:
                case errorKind.PERMISSION_DENIED:
                    setReauthenticate(true)
                    break
                case errorKind.BAD_REQUEST:
                    setShouldRefresh(true)
                    setErrorMessage(message)
                    break
                default:
                    setErrorMessage(message)
            }
            return true
        }

        if (
            handleActionError(subscriptionAction.error) ||
            handleActionError(cancelSubscriptionAction.error)
        ) {
            return
        }

        if (subscriptionAction.data) {
            onSuccess()
        }

        if (cancelSubscriptionAction.data) {
            onSuccess('cancel')
        }

        if (paymentInterval === 'semi_monthly') {
            const isValid =
                firstPayment >= 1 &&
                secondPayment <= 31 &&
                secondPayment - firstPayment >= 14 &&
                31 + firstPayment - secondPayment >= 14
            setIsValidSemiMonthly(isValid)
        }
    }, [
        firstPayment,
        secondPayment,
        amount,
        contract.payoff,
        startDate,
        paymentInterval,
        stopAutoPayOn,
        numberOfPayments,
        subscriptionAction,
        cancelSubscriptionAction,
        onSuccess
    ])

    if (errorMessage) {
        return (
            <>
                <Heading size="md">Subscription Request Failed</Heading>
                <p>{errorMessage}</p>
                <button type="button" onClick={() => onCancel(shouldRefresh)}>
                    OK
                </button>
            </>
        )
    }

    // Handle loading state for the doSubscriptionAction API call
    if (subscriptionAction.loading || cancelSubscriptionAction.loading) {
        return <Spinner />
    }

    const minAmount = paymentRestrictionAmount(dealership.min_payment, contract)
    const maxAmount = paymentRestrictionAmount(dealership.max_payment, contract)
    let restrictions = []
    if (minAmount) {
        restrictions.push(`min ${formatMoney(minAmount)}`)
    }
    if (maxAmount) {
        restrictions.push(`max ${formatMoney(maxAmount)}`)
    }
    const amountInputLabel =
        restrictions.length > 0 ? (
            <div className={styles.paymentAmountLabel}>
                <span className={styles.paymentAmountLabelText}>
                    Payment Amount
                </span>
                <wbr />
                <span className={styles.paymentAmountLabelRestriction}>
                    {restrictions.join(' ')}
                </span>
            </div>
        ) : (
            <div className={styles.paymentAmountLabel}>
                <span className={styles.paymentAmountLabelText}>
                    Payment Amount
                </span>
            </div>
        )

    const handleSubmit = (confirmed = false) => {
        // Ensure amount only contains digits and up to one decimal point after commas and leading/trailing spaces are stripped.
        if (
            !amount
                .trim()
                .replace(/,/g, '')
                .match(/^\$?\d*\.?\d*$/)
        ) {
            setErrorMessage(
                'The payment amount entered contains one or more invalid characters.'
            )
            return
        }

        // If confirmed is false, we need to get confirmation of the input.
        if (!confirmed) {
            setConfirm(true)
            return
        }

        // Make the API call
        doSubscriptionAction(doSubscriptionCfg)
    }

    const handleCancel = (confirmCancel = false) => {
        // If confirmed is false, we need to get confirmation of the input.
        if (!confirmCancel) {
            setConfirmCancel(true)
            return
        }

        // Make the API call
        doCancelSubscriptionAction(doCancelSubscriptionCfg)
    }

    const handlePrint = () => {
        // Select the form element
        const formElement = document.getElementById('printableForm')
        if (!formElement) {
            console.error(
                'Form element not found. Ensure the correct class name is used.'
            )
            return
        }

        // Get the form's outerHTML for printing
        const formContent = formElement.outerHTML

        // Create a new window for printing
        const printWindow = window.open('', '_blank')
        if (!printWindow) {
            console.error(
                'Failed to open new window. Check browser popup settings.'
            )
            return
        }

        // Build the printable HTML structure
        const printableHTML = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Print Agreement</title>
                <style>
                    body {
                        background: var(--color-grey-xlight);
                        border-radius: var(--border-radius-normal);
                        padding: 24px;
                        margin: 0px 5% 0px 5%;
                        font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
                    }
                    ul {
                        margin-left: 20px;
                    }
                    #dateBoxleft {
                        width: 40%;
                        float: left;
                    }
                    #signatureBoxRight {
                        text-align: start;
                    }
                    #bottomBox {
                        margin: 25px 0 25px 0;
                    }
                </style>
            </head>
            <body>
                ${formContent}
            </body>
            </html>
        `

        // Write content to the new window
        printWindow.document.open()
        printWindow.document.write(printableHTML)
        printWindow.document.close()

        // Wait for the content to fully load before printing
        printWindow.onload = () => {
            printWindow.focus()
            printWindow.print()
        }
    }

    const addDateSuffix = (date) => {
        if (typeof date !== 'number' || date < 1 || date > 31) {
            throw new Error('Input must be a number between 1 and 31.')
        }

        const suffixes = ['th', 'st', 'nd', 'rd']
        const remainder = date % 10
        const exception = date % 100

        // Handle special cases like 11th, 12th, 13th
        if (exception >= 11 && exception <= 13) {
            return `${date}th`
        }

        // Determine suffix based on the last digit
        const suffix = suffixes[remainder] || 'th'

        return `${date}${suffix}`
    }

    return (
        <>
            <Form
                disabled={
                    subscriptionAction.loading || !!subscriptionAction.data
                }
                onSubmit={() => {
                    subscription ? handleCancel() : handleSubmit()
                }}
                onCancel={() => onCancel(shouldRefresh)}
                coloredButtons={!subscription}
                submitButtonText={subscription ? 'STOP AUTO PAY NOW' : 'SUBMIT'}
                cancelButtonText={subscription ? 'CLOSE' : 'CANCEL'}
            >
                <LabeledInput
                    label={amountInputLabel}
                    type="number"
                    step="1"
                    min={minAmount > 0 ? minAmount : 0.01}
                    max={maxAmount > 0 ? maxAmount : 999999.99}
                    required
                    value={amount}
                    onChange={(e) => {
                        setAmount(e.target.value)
                    }}
                    disabled={
                        !!(
                            subscriptionAction.loading ||
                            !!subscriptionAction.data ||
                            subscription?.amount
                        )
                    }
                    className={styles.amountInput}
                />
                <label className={styles.labelFirstPayment}>
                    First Payment
                </label>
                <div
                    style={{
                        width: '45%',
                        'margin-right': '100px'
                    }}
                >
                    <DatePicker
                        label=""
                        type="datepicker"
                        shouldCloseOnSelect={true}
                        selected={startDate}
                        onChange={(date) => setStartDateHandler(date)}
                        minDate={tomorrowsDate}
                        disabled={!!subscription?.start_date}
                    />
                </div>
                <LabeledInput
                    label="Payment Schedule"
                    type="select"
                    value={paymentInterval}
                    onChange={(e) => {
                        setPaymentInterval(e.target.value)
                    }}
                    required
                    disabled={!!subscription?.interval}
                >
                    <option key="monthly" value="monthly">
                        Monthly
                    </option>
                    <option key="semi_monthly" value="semi_monthly">
                        Semi-Monthly
                    </option>
                    <option key="bi_weekly" value="bi_weekly">
                        Biweekly
                    </option>
                    <option key="weekly" value="weekly">
                        Weekly
                    </option>
                </LabeledInput>
                <div className={styles.intervalOptions}>
                    {paymentInterval === 'weekly' && (
                        <label>
                            Payment will be charged every{' '}
                            {new Date(startDate).toLocaleDateString('en-US', {
                                weekday: 'long'
                            })}
                            .
                        </label>
                    )}
                    {paymentInterval === 'bi_weekly' && (
                        <label>
                            Payment will be charged every other{' '}
                            {new Date(startDate).toLocaleDateString('en-US', {
                                weekday: 'long'
                            })}
                            .
                        </label>
                    )}
                    {paymentInterval === 'monthly' && (
                        <label>
                            Payment will be charged on the{' '}
                            {addDateSuffix(new Date(startDate).getDate())} of
                            every month.
                        </label>
                    )}

                    {paymentInterval === 'semi_monthly' && (
                        <>
                            <label>Payment will be charged on the</label>
                            <div className={styles.smOptionsContainer}>
                                <input
                                    className={styles.smElementStyle}
                                    type="number"
                                    value={firstPayment}
                                    min={1}
                                    onChange={(e) =>
                                        setFirstPayment(Number(e.target.value))
                                    }
                                    disabled={!!subscription}
                                />

                                <label className={styles.smElementStyle}>
                                    and
                                </label>

                                <input
                                    style={{ width: '25%' }}
                                    type="number"
                                    value={secondPayment}
                                    max={31}
                                    onChange={(e) =>
                                        setSecondPayment(Number(e.target.value))
                                    }
                                    disabled={!!subscription}
                                />
                                <label>of every month.</label>
                            </div>
                            {!isValidSemiMonthly && (
                                <p style={{ color: 'red' }}>
                                    Invalid semi-monthly configuration
                                </p>
                            )}
                        </>
                    )}
                </div>
                <LabeledInput
                    label="Stop Auto Pay"
                    type="select"
                    value={stopAutoPayOn}
                    onChange={(e) => {
                        setStopAutoPayOn(e.target.value)
                    }}
                    required
                    disabled={!!subscription?.autopay_stop_on}
                >
                    <option key="stop_date" value="stop_date">
                        Choose Date
                    </option>
                    <option key="number_payments" value="number_payments">
                        # of Payments
                    </option>
                    <option key="zero_balance" value="zero_balance">
                        Zero Balance
                    </option>
                </LabeledInput>
                <div>
                    <div>
                        {stopAutoPayOn === 'stop_date' && (
                            <div
                                style={{
                                    width: '45%',
                                    'margin-right': '100px'
                                }}
                            >
                                <DatePicker
                                    label=""
                                    type="datepicker"
                                    shouldCloseOnSelect={true}
                                    selected={endDate}
                                    onChange={(date) => setEndDateHandler(date)}
                                    minDate={dayAftertomorrowsDate}
                                    disabled={!!subscription?.end_date}
                                />
                            </div>
                        )}

                        {stopAutoPayOn === 'number_payments' && (
                            <LabeledInput
                                label=""
                                type="number"
                                step="1"
                                min="2"
                                onChange={(e) => {
                                    setNumberOfPayments(
                                        parseInt(e.target.value)
                                    )
                                }}
                                value={numberOfPayments}
                                className={styles.amountInput}
                                disabled={!!subscription?.number_of_payments}
                            />
                        )}
                    </div>
                    <div>
                        {subscription && (
                            <p className={styles.stopAutopayTollTip}>
                                You can still cancel manually at any time.
                            </p>
                        )}
                    </div>
                </div>
                <fieldset
                    disabled={
                        subscriptionAction.loading || !!subscriptionAction.data
                    }
                >
                    <legend>Payment Method</legend>
                    {paymentMethods.map((pm) => {
                        return (
                            <LabeledInput
                                label={
                                    pm.nickname || `${pm.provider} ${pm.last_4}`
                                }
                                type="radio"
                                name="payment-method"
                                required
                                checked={selectedPaymentMethod.id === pm.id}
                                onChange={() => {
                                    setSelectedPaymentMethod(pm)
                                }}
                                key={`payment-method-${pm.id}`}
                                disabled={ !!subscription}
                            />
                        )
                    })}
                </fieldset>
            </Form>
            {confirm && (
                <Overlay>
                    <ConfirmPayment
                        amount={totalAmount}
                        startDate={formatDate(startDate)}
                        endDate={formatDate(endDate)}
                        user={user}
                        interval={paymentInterval}
                        firstPayment={firstPayment}
                        secondPayment={secondPayment}
                        numberOfPayments={numberOfPayments}
                        stopAutoPayOn={stopAutoPayOn}
                        dealership={dealership}
                        method={selectedPaymentMethod}
                        contract={contract}
                        onConfirm={() => {
                            setConfirm(false)
                            handleSubmit(true)
                        }}
                        onCancel={() => {
                            setConfirm(false)
                        }}
                        onPrint={() => {
                            handlePrint()
                        }}
                    />
                </Overlay>
            )}
            {confirmCancel && (
                <Overlay>
                    <ConfirmCancelSubscription
                        contract={contract}
                        amount={totalAmount}
                        method={selectedPaymentMethod}
                        paymentDate={startDate}
                        onConfirm={() => {
                            setConfirmCancel(false)
                            handleCancel(true)
                        }}
                        onCancel={() => {
                            setConfirmCancel(false)
                        }}
                    />
                </Overlay>
            )}
            {reauthenticate && (
                <Overlay>
                    <ReauthenticateForm
                        onSuccess={() => {
                            setReauthenticate(false)
                            subscription
                                ? handleCancel(true)
                                : handleSubmit(true)
                        }}
                        onCancel={() => {
                            onCancel(shouldRefresh)
                        }}
                    />
                </Overlay>
            )}
        </>
    )
}

SubscriptionForm.propTypes = {
    contract: contractPropType.isRequired,
    paymentMethods: PropTypes.arrayOf(paymentMethodPropType).isRequired,
    subscription: subscriptionPropType,
    user: userPropType,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

export default SubscriptionForm
