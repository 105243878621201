import PropTypes, { number, string } from 'prop-types'
import Form from 'components/Form'
import Heading from 'components/Heading'
import styles from './ConfirmSubscription.module.css'

const ConfirmPayment = ({
    amount,
    startDate,
    endDate,
    user,
    interval,
    firstPayment,
    secondPayment,
    numberOfPayments,
    stopAutoPayOn,
    dealership,
    method,
    contract,
    onConfirm,
    onCancel,
    onPrint
}) => {
    const formatDate = (date) => {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    let firstExecutionDate = startDate

    const addDateSuffix = (date) => {
        if (typeof date !== 'number' || date < 1 || date > 31) {
            throw new Error('Input must be a number between 1 and 31.')
        }

        const suffixes = ['th', 'st', 'nd', 'rd']
        const remainder = date % 10
        const exception = date % 100

        // Handle special cases like 11th, 12th, 13th
        if (exception >= 11 && exception <= 13) {
            return `${date}th`
        }

        // Determine suffix based on the last digit
        const suffix = suffixes[remainder] || 'th'

        return `${date}${suffix}`
    }

    //For semi-monthly the first execution date should be calculated on 2 dates provided by user which ever is near
    function getFirstExecutionDate(startDate, firstDate, secondDate) {
        const start = new Date(startDate)
        let nextPayment
        
        if (start.getDate() <= firstDate) {
            nextPayment = new Date(start.getFullYear(), start.getMonth(), firstDate)
        } else if (start.getDate() <= secondDate) {
            nextPayment = new Date(start.getFullYear(), start.getMonth(), secondDate)
        } else {
            nextPayment = new Date(start.getFullYear(), start.getMonth() + 1, firstDate)
        }
        
        return nextPayment
    }

    let intervalStr = ''

    if (interval === 'weekly') {
        intervalStr = `every ${new Date(startDate).toLocaleDateString('en-US', {
            weekday: 'long'
        })}`
    } else if (interval === 'bi_weekly') {
        intervalStr = `every other ${new Date(startDate).toLocaleDateString(
            'en-US',
            {
                weekday: 'long'
            }
        )}`
    } else if (interval === 'semi_monthly') {
        firstExecutionDate = getFirstExecutionDate(startDate, firstPayment, secondPayment)
        intervalStr = `on the ${addDateSuffix(
            firstPayment
        )} and the ${addDateSuffix(secondPayment)} of each month`
    } else {
        const day = new Date(startDate).getDate()
        intervalStr = `on the ${addDateSuffix(day)} of each month`
    }

    let endStr = `end on ${formatDate(new Date(endDate))}`
    if (stopAutoPayOn === 'number_payments') {
        endStr = `end after ${numberOfPayments} payments`
    } else if (stopAutoPayOn === 'zero_balance') {
        endStr = `end on when remaining balance reaches to zero`
    }

    return (
        <Form
            onSubmit={onConfirm}
            onCancel={onCancel}
            onPrint={onPrint}
            coloredButtons={true}
            submitButtonText="ACCEPT"
            printButtonText="PRINT AGREEMENT"
            className={styles.form}
        >
            <div id="printableForm" className={styles.contentBox}>
                <Heading className={styles.headline} size="md">
                    RECURRING PAYMENT AGREEMENT
                </Heading>
                <div>
                    By clicking "Accept" below, you, {user.nickname}, authorize{' '}
                    {dealership.business_name} to charge your credit card on
                    file (ending in {method.last_4}) in the amount of {amount}{' '}
                    {intervalStr}. This payment schedule will begin on{' '}
                    {formatDate(new Date(firstExecutionDate))} and {endStr}. A receipt
                    for each payment will be emailed to you.
                </div>
                <Heading className={styles.headline} size="sm">
                    You understand that:
                </Heading>
                <ul>
                    <li>
                        You can cancel this recurring payment authorization at
                        any time by logging into your account online and
                        clicking on “Stop Autopay.”
                    </li>
                    <li>
                        This recurring payment will automatically stop on the
                        end date that you specified, or when your remaining
                        balance becomes lower than your regular scheduled
                        contract payment.
                    </li>
                    <li>
                        You will need to contact Frazer Motors, Inc. to make
                        your final payoff, or any payments toward a separate
                        repair balance.
                    </li>
                </ul>
                <Heading className={styles.headline} size="sm">
                    Electronic Signature
                </Heading>
                <div>
                    By clicking "Accept," I acknowledge that I have read and
                    understand this agreement and authorize the recurring
                    charges to my credit card as outlined above. I further
                    acknowledge that this electronic signature is the legal
                    equivalent of my handwritten signature.
                </div>
                <div id="bottomBox" className={styles.bottomBox}>
                    <div id="dateBoxleft" className={styles.dateBoxleft}>
                        {formatDate(new Date())}
                    </div>
                    <div
                        id="signatureBoxRight"
                        className={styles.signatureBoxRight}
                    >
                        {user.nickname}
                    </div>
                </div>
            </div>
        </Form>
    )
}

ConfirmPayment.propTypes = {
    amount: number,
    startDate: string,
    endDate: string,
    user: PropTypes.shape({
        nickname: string
    }).isRequired,
    interval: string,
    firstPayment: number,
    secondPayment: number,
    numberOfPayments: number,
    stopAutoPayOn: string,
    dealership: PropTypes.shape({
        business_name: string
    }).isRequired,
    method: PropTypes.shape({
        last_4: string
    }).isRequired,
    contract: PropTypes.shape({
        payoff: number
    }).isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onPrint: PropTypes.func.isRequired
}

export default ConfirmPayment
